.container {
    padding: 12px;
    background: #fff;
    border-radius: 8px;

    .content {
        .list {
            margin-top: -12px;
        }
    }

    h4 {
        color: var(--gray-900, #323232);
        font-size: 14px !important;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 20px;
        text-transform: none !important;
    }
}