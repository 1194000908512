.action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
    margin-bottom: 40px;
}

.right-buttons {
    display: flex;
    gap: 12px;
}
