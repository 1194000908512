.container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    color: var(--gray-800, #505050);
    font-family: 'Open Sans';

    .back {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--gray-800, #505050);
        gap: 10px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        width: fit-content;
    }

    .content {
        display: flex;
        align-items: center;

        border-radius: 6px;
        background: var(--white, #fff);
        padding: 12px 16px;

        .title {
            flex: 1;

            p {
                color: var(--gray-900, #323232);

                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 130% */
            }

            .badge {
                padding: 2px 8px;
                gap: 4px;
                border-radius: 10px;

                background: var(--green-600, #268e6c);
                color: var(--white, #fff);

                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: 12px; /* 120% */
                text-transform: uppercase;
            }
        }

        .buttons {
            display: flex;
            gap: 8px;

            button {
                width: 36px;
                height: 36px;
                border-radius: 4px;
                background: var(--gray-200, #f4f4f4);
                padding: 8px;
            }

            .main-button {
                width: fit-content;
            }
        }
    }
}
