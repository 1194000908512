.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    margin-bottom: 8px;
    border: 1px solid var(--gray-300);
    border-radius: 6px;

    .title {
        color: var(--Gray-900, #323232);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}


.error {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    margin-bottom: 8px;
    border: 1px solid #EC5B62 !important;
    border-radius: 6px;

    .title {
        color: var(--Gray-900, #323232);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}