.container {
    padding: 12px;
    margin-top: 8px;
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 12px;
}

.section {
    margin-top: 12px;
}

h3,
h4 {
    margin: 0;
    font-weight: normal;
}

p {
    margin: 5px 0;
    color: #666;
}

.radio-group {
    display: flex;
    margin-left: -12px;
    gap: 24px;

    .radio-item {
        color: var(--gray-900, #323232);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}

.title {
    font-weight: 600;
    color: var(--gray-900);
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 6px;
}

.description {
    font-size: 12px;
    color: var(--gray-900);
    line-height: 18px;
    margin-bottom: 12px;
}

.subtitle {
    color: var(--gray-900, #323232);
    font-size: 14px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
    text-transform: none !important;
}

.subtitle-description {
    color: var(--gray-600, #666666);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.uf-check-picker {
    width: 340px;
}

.check-picker-error {
    width: 340px;
    :global(.rs-picker-toggle) {
        border: 1px solid #EC5B62 !important;
    }
}