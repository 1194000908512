.channel-container {
    padding: 12px;
    margin-top: 8px;
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 12px;
}

.title {
    color: var(--gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.description {
    color: var(--gray-600) !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.checkbox-group {
    margin-left: -12px;
    display: flex;
    flex-direction: column;
}

.checkbox-group-paragraph {
    color: var(--gray-600) !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-left: 34px;
    margin-top: -8px;
}

input[type='checkbox']:checked + .checkbox-label {
    font-weight: bold;
}
