.container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 6px;
    background: var(--white, #fff);

    font-family: 'Open Sans';

    .title {
        display: flex;
        gap: 8px;

        color: var(--gray-900, #323232);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;

        .box {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 12px;

            border-radius: 6px;
            border: 1px solid var(--gray-300, #eaeaea);
            background: var(--gray-100, #f5f5f5);

            .title {
                color: var(--gray-900, #323232);

                font-size: 12px;
                font-weight: 700;
                line-height: 18px; /* 150% */
                text-transform: uppercase;

                p {
                    color: var(--gray-700, #747474);

                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px; /* 150% */
                    text-transform: initial;
                }
            }

            .body {
                display: flex;
                padding: 12px;
                flex-direction: column;
                gap: 8px;

                border-radius: 6px;
                border: 1px solid var(--gray-300, #eaeaea);
                background: var(--white, #fff);

                & > .label {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 8px;

                    span {
                        color: var(--gray-900, #323232);

                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        text-transform: capitalize;
                    }

                    p {
                        color: var(--gray-700, #747474);

                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px; /* 150% */
                    }
                }

                .inputs {
                    display: flex;
                    gap: 16px;
                }

                .input-group {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    color: var(--gray-800, #505050);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */

                    .info {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                    }

                    .weekdays :global(.rs-checkbox-checker) {
                        padding-left: 24px !important;
                    }

                    .weekdays :global(.rs-checkbox-wrapper) {
                        left: 0px !important;
                    }
                }
            }
        }
    }
}
