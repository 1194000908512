@import '../../../../../style/typography.scss';
@import '../../../../../style/app.scss';

.analise-de-cobertura-info {
    padding: 16px;
    background-color: #fef9f4;
    border-radius: 6px;
    margin-top: 8px;
    margin-bottom: 8px;

    p {
        @extend .font-size-100-regular;
    }
}

.setup-isa__main-config {
    padding: 16px;
    background-color: #fff;
    border-radius: 6px;
    margin: 0;

    h3 {
        @extend .font-size-200-semibold;
    }

    &__header {
        &__subtitle {
            @extend .font-size-100-regular;
        }
    }
}

.setup-isa__price-preference-competitor {
    border-radius: 6px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #fff;

    &__content {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
            margin-top: 0;
        }

        h4 {
            @extend .font-size-100-semibold;
            text-transform: none;
        }

        p {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.setup-isa__main-config__footer {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
}

.footer {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.wrapper-dias-concorrente {
    display: flex;
    align-items: center;
    gap: 16px;

    .input-box {
        width: 96px;
    }

    > span {
        @extend .font-size-75-regular;
        --text-color: #959595;
        padding-block: 8px;
    }
}

.setup-isa__price-preference-competitor__option-radio {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    :global(.rs-radio-wrapper) {
        top: 30px;
    }

    :global(.rs-radio) {
        margin-left: -13px;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
            color: var(--gray-900);
            @extend .font-size-100-semibold;
            font-size: 14px;
            line-height: 20px;
            text-transform: none;
        }

        span {
            color: var(--gray-600);
            @extend .font-size-75-regular;
            font-size: 12px;
            line-height: 18px;
        }
    }
}

.setup-isa__price-preference-competitor__toggle {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .toggle-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.setup-isa__price-preference-competitor__option-checkbox {
    border: 1px solid var(--gray-300);
    padding: 12px;
    border-radius: 6px;
    margin-top: 15px;

    :global(.rs-checkbox-wrapper) {
        top: 28px;
    }

    :global(.rs-checkbox) {
        margin-left: -13px;
    }

    h4 {
        text-transform: none !important;
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    span {
        color: var(--gray-600);
        @extend .font-size-75-regular;
        font-size: 12px;
        line-height: 18px;
    }

    .setup-isa__price-preference-competitor__option-checkbox-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .checkbox-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'item1 item5'
            'item2 item6'
            'item3 item7'
            'item4 item8';
        gap: 16px;
    }

    .setup-isa__price-preference-competitor__option-checkbox-item {
        display: flex;
        align-items: center;

        &:nth-child(1) {
            grid-area: item1;

            &:nth-child(2) {
                grid-area: item2;
            }

            &:nth-child(3) {
                grid-area: item3;
            }

            &:nth-child(4) {
                grid-area: item4;
            }

            &:nth-child(5) {
                grid-area: item5;
            }

            &:nth-child(6) {
                grid-area: item6;
            }

            &:nth-child(7) {
                grid-area: item7;
            }

            &:nth-child(8) {
                grid-area: item8;
            }

            .text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 600px;
                width: 100%;

                h4 {
                    color: var(--gray-900);
                    @extend .font-size-100-semibold;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: none;
                    opacity: 0.5;
                }

                span {
                    color: var(--gray-600);
                    @extend .font-size-75-regular;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }
}
