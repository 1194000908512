.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
}

th,
td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
}

th:first-child,
td:first-child {
    border-top-left-radius: 8px;
}

th:last-child,
td:last-child {
    border-top-right-radius: 8px;
}

.normal {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.opacity {
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.7;
}

.currentFileLabel {
    background-color: #33AB84;
    border-radius: 8px;
    width: 8px;
    height: 8px;
    margin-left: 8px;
}

.fileName {
    display: flex;
    align-items: center;
}

.btn-filename {
    font-size: 12px;
    padding: 0;
    text-decoration: none !important;
    line-height: inherit;
}