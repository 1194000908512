.upload-box {
    margin-top: 24px;
    padding: 12px;
    border: 2px solid #eaeaea;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease;

    .upload-content {
        display: flex;
        flex-direction: column;
        border: 1px solid #a5ccf8;
        width: 100%;
        max-width: 500px;
        padding: 8px;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            border-color: #0066cc;
            background-color: rgba(0, 102, 204, 0.1);
        }
    }
}

.upload-title {
    font-size: 14px !important;
    font-weight: 500;
    color: #333;
    display: flex;
    align-items: center;
    gap: 8px;
}

.upload-description {
    font-size: 12px !important;
    color: #666;
    margin-top: 4px;
}

.upload-box-error {
    margin-top: 24px;
    padding: 12px;
    border: 2px solid #EC5B62;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease;

    .upload-content-error {
        display: flex;
        flex-direction: column;
        border: 1px solid #FBDEE0;
        width: 100%;
        max-width: 500px;
        padding: 8px;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            border-color: #EC5B62;
            background-color: #FBDEE0;
        }
    }
}

.upload-title-error {
    font-size: 14px !important;
    font-weight: 500;
    color: #EC5B62 !important;
    display: flex;
    align-items: center;
    gap: 8px;
}

.upload-description-error {
    font-size: 12px !important;
    color: #EC5B62 !important;
    margin-top: 4px;
}