.modal {
    width: 100%;
    max-width: 520px;

    :global(.rs-modal-content) {
        padding-left: 0;
        padding-right: 0;
    }
}

.content {
    padding-right: 24px;
    padding-left: 24px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: var(--gray-900);
    line-height: 20px;
    margin-bottom: 6px;
    padding-left: 24px;
}

.description {
    color: var(--gray-800);
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 12px;
}

.uploadButton {
    margin-bottom: 24px;
    margin-top: 24px;
    background-color: #378ef0;
    color: white;
    padding: 12px;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
}

.infoBox {
    background-color: #f5f9fe;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 24px;

    &Text {
        text-align: center;
        color: var(--gray-800);
        font-size: 14px;
        line-height: 20px;
    }

    &Link {
        display: block;
        text-align: center;
        color: #378ef0;
        text-decoration: none;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }
}

.footer {
    text-align: right;
    padding: 24px 24px 0 24px;
    border-top: 2px solid var(--gray-300, #eaeaea);
}

.closeButton {
    color: #3498ff;
    border: 1px solid #3498ff;
    font-weight: 600;
    text-transform: uppercase;
}

.uploadProgress {
    text-align: center;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .fileName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        background: #f5f5f5;
        padding: 12px;
        border-radius: 4px;
    }

    .fileNameContent {
        display: flex;
        align-items: center;
        max-width: 80%;
    }

    .fileIcon {
        margin-right: 8px;
        display: flex;
        align-items: center;
    }

    .fileNameText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .fileSize {
        margin-left: 8px;
        color: #666;
    }

    :global(.rs-progress-circle) {
        margin: 0 auto;
    }

    p {
        margin-top: 16px;
        color: #666;
        font-size: 14px;
    }
}

.successState {
    text-align: center;
    padding: 20px 0;

    .fileName {
        background: #f5f5f5;
        padding: 12px;
        border-radius: 4px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;

        .fileNameContent {
            display: flex;
            align-items: center;
            flex: 1;
            min-width: 0;
        }

        .fileNameText {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
        }

        .fileIcon {
            flex-shrink: 0;
            margin-right: 8px;
        }

        .fileSize {
            flex-shrink: 0;
            margin-left: auto;
            color: #666;
            font-size: 14px;
        }
    }

    .checkmark {
        h4 {
            margin-top: 12px;
            margin-bottom: 2px;
            color: var(--gray-800);
            font-weight: 600;
            line-height: 18px;
            font-size: 12px;
        }

        p {
            color: var(--gray-800);
            font-size: 12px;
            max-width: 80%;
            margin: 0 auto;

            span {
                color: var(--gray-800);
                font-weight: 600;
                font-size: 12px;
            }
        }
    }
}

.uploadMessage {
    font-size: 12px !important;
    text-align: center;
    margin: 16px 0;

    strong {
        font-weight: 700;
    }
}

.cancelButton {
    font-size: 12px;
    color: #3498ff;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0 0 0 4px;

    &:hover {
        color: #2589f5;
    }
}

.buttonsContainer {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--gray-300, #eaeaea);
    padding-top: 24px;
}

.closeErrorButton {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--blue-400, #378ef0);
    color: var(--blue-400, #378ef0);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    background: #fff;

    &:hover {
        background: #fff;
        color: var(--blue-400, #378ef0);
    }
}

.downloadButton {
    display: flex;
    gap: 12px;
    text-transform: uppercase;
}

.modalTitle {
    color: var(--gray-900, #323232);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.upload-message-wrapper {
    display: flex;
}