.title {
    font-weight: 600;
    color: var(--gray-900);
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 6px;
}

.description {
    font-size: 12px;
    color: var(--gray-900);
    line-height: 18px;
    margin-bottom: 12px;
}

.link {
    color: #0066cc;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}